import "./twinklestyles.scss";
//credits: Anastasia Goodwin: https://codepen.io/agoodwin/pen/NMJoER

function Twinkle() {
  return (
    <div class="background-container">
      <div class="stars"></div>
      <div class="twinkling"></div>
      <div class="twinkling2"></div>

      {/* <div class="moonpng"></div> */}
      {/* <img class="moonpng" src={Moon} alt="oopsie monki can't find"></img> */}
    </div>
  );
}
export default Twinkle;
