import "./shootingstarstyle.scss";
//credits: Yusuke Nakaya: https://codepen.io/YusukeNakaya/pen/XyOaBj

function ShootingStars() {
  return (
    <div class="backgrounddiv">
      <div class="night">
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
      </div>
    </div>
  );
}
export default ShootingStars;
